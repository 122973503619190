const About = () => {
    return (
        <>
            <section class="main-blog author-banr">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-6">
                            <div class="main-log-left">
                                <h2>Authors Behind<br/>
                                    the Post</h2>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="main-log-right">
                                <img src={require("../assets/images/author-bnr.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="author-desc">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-6">
                            <div class="author-lef">
                                <h2>Shante Zhou
                                    </h2>
                                <ul>
                                    <li><span>Fb</span></li>
                                    <li><span>Tw</span></li>
                                    <li><span>Lg</span></li>
                                    <li><span>Pi</span></li>
                                </ul>
                                {/* <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.Bring to the table win-win survival strategies to ensure proactive domination.</p> */}
                                <a href="https://www.linkedin.com/in/shante-zhou-b1315726b/" target="_blank">Follow</a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="author-right">
                                <img src={require("../assets/images/aboutone.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="author-desc">
                <div class="container">
                    <div class="row d-flex align-items-center">
                    <div class="col-md-6">
                            <div class="author-right">
                                <img src={require("../assets/images/abouttwo.png")} alt="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="author-lef px-4">
                                <h2>Joseph Ngo
                                    </h2>
                                <ul>
                                    <li><span>Fb</span></li>
                                    <li><span>Tw</span></li>
                                    <li><span>Lg</span></li>
                                    <li><span>Pi</span></li>
                                </ul>
                                {/* <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.Bring to the table win-win survival strategies to ensure proactive domination.</p> */}
                                <a href="https://www.linkedin.com/in/j-h-ngo-988030256/" target="_blank">Follow</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
            {/* <section class="author-des-blog">
                <div class="container">
                    <div class="outer-auth">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-5">
                                <div class="other-post-lft">
                                    <img src={require("../assets/images/author-post.png")} alt="" />
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="other-post-thy">
                                    <h2>Do things on February 14-Valentine's
                                        Day with Loved Ones</h2>
                                    <h5>15 February 2020 </h5>
                                    <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                                    <a href="#">See more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="outer-auth">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-5">
                                <div class="other-post-lft">
                                    <img src={require("../assets/images/author-post.png")} alt="" />
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="other-post-thy">
                                    <h2>Do things on February 14-Valentine's
                                        Day with Loved Ones</h2>
                                    <h5>15 February 2020 </h5>
                                    <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                                    <a href="#">See more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="outer-auth">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-5">
                                <div class="other-post-lft">
                                    <img src={require("../assets/images/author-post.png")} alt="" />
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="other-post-thy">
                                    <h2>Do things on February 14-Valentine's
                                        Day with Loved Ones</h2>
                                    <h5>15 February 2020 </h5>
                                    <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                                    <a href="#">See more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="auth-btn">
                        <a href="#">Show more</a>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default About;
