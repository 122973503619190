import { Link } from "react-router-dom";
import newone from "../assets/images/newone.png"
import linkone from "../assets/images/linkone.png"
import linktwo from "../assets/images/linktwo.png"
import linkthree from "../assets/images/linkthree.png"
import linkfour from "../assets/images/linkfour.png"

const Footer = () => {
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      };
    return (
        <>
            <footer class="main-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="footer-left">
                                <div class="logo-ft">
                                    <Link to='/' onClick={handleClick}><img src={newone} alt="" /></Link>
                                </div>
                                {/* <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy.</p> */}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="footer-right">
                                <ul class="ft-link">
                                    <li >
                                        <Link class="nav-link active" to="/" onClick={handleClick}><img src={linkone} alt=""/>Home</Link>
                                    </li>
                                    <li >
                                        <Link class="nav-link" to="/about" onClick={handleClick}><img src={linktwo} alt=""/>About Us</Link>
                                    </li>
                                    <li >
                                        <Link class="nav-link" to="/blog" onClick={handleClick}><img src={linkthree} alt=""/>Blog</Link>
                                    </li>
                                    <li >
                                        <Link class="nav-link" to="/contact" onClick={handleClick}><img src={linkfour} alt=""/>Contact</Link>
                                    </li>
                                </ul>
                                {/* <ul class="social-link">
                                    <li><a href="#">Dr</a></li>
                                    <li><a href="#">Be</a></li>
                                    <li><a href="#">Lg</a></li>
                                    <li><a href="#">Tw</a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div class="copy-right">
                        <p>Copyright ©2023 wagedifferential.org</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;